// import React from "react"
// import { Redirect } from "react-router-dom"


//Pages
import PageGameDetails from "pages/PageGameDetails"
import Page404 from "pages/Page404";

const userRoutes = [
  { path: "/game/:slug", component: PageGameDetails },
  { path: "/404", component: Page404},
  { path: "*", component: Page404},
]


export { userRoutes }
