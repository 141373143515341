//= Scripts
import axios from 'axios';
import moment from "moment";
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

//= Mui
import {Box, Button, Link, List, ListItem, ListItemText, Typography} from "@mui/material";
import {QRCodeSVG} from "qrcode.react";

const GameDetails = (props) => {

    const [persons, setPersons] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        getProducts()
    }, []);

    const getProducts = async() => {
        await axios.get(`${process.env.REACT_APP_API}/${props.slug}/infos.data`)
            .then(res => {
                const persons = res.data;
                setPersons(persons);
            })
            .catch(error => {
                navigate('/404');
                console.log(error)
            });
    }

    let numOfDevices = 0, urlIcon = '';
    if (Object.keys(persons).length) {
        numOfDevices = persons.deviceUDIDs.length
        urlIcon = persons.bundleIcons[0]
    }


    return (
        <>
            <header className="App-header">
                <img
                    src={urlIcon}
                    srcSet={urlIcon}
                    className="App-logo"
                    alt={persons.bundleDisplayName}
                    loading="lazy"
                />

                <Typography component="h1" variant="h5">
                    {persons.bundleDisplayName}
                </Typography>
                <div>Version {persons.bundleVersion}</div>
            </header>

            <Box className="game-details" sx={{my: 2}}>
                <Button variant="contained" disableElevation fullWidth sx={{mb: 2}} href={persons.manifestUrl}>Download</Button>

                <Typography className="game-desc" align="left" sx={{mb: 2}}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                    the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                    type
                    and scrambled it to make a type specimen book.
                </Typography>

                <Box
                    className="list-devices"
                    sx={{
                        backgroundColor: 'primary.dark',
                        color: 'white',
                        p: 1,
                        mb: 2,
                        fontSize: 12
                    }}
                >
                    <Typography sx={{mb: 2, fontWeight: 'medium'}} variant="subtitle1" component="div"
                                align={"left"}>
                        List of Devices ({numOfDevices})
                    </Typography>
                    <List sx={{
                        maxHeight: 200,
                        position: 'relative',
                        overflow: 'auto',
                    }}>
                        {(persons.deviceUDIDs || []).map((udid, index) => (
                                <ListItem disablePadding key={index}>
                                    <ListItemText
                                        primary={udid}
                                        primaryTypographyProps={{
                                            fontWeight: 'light',
                                            variant: 'body2',
                                        }}/>
                                </ListItem>
                            )
                        )}

                        {/*<ListItem disablePadding>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="00008030-000D08163E68C02E"*/}
                        {/*        primaryTypographyProps={{*/}
                        {/*            fontWeight: 'light',*/}
                        {/*            variant: 'body2',*/}
                        {/*        }}/>*/}
                        {/*</ListItem>*/}
                    </List>
                </Box>

                <Box
                    className="more-details"
                    sx={{
                        backgroundColor: 'primary.dark',
                        color: 'white',
                        p: 1,
                        mb: 2,
                        fontSize: 12
                    }}
                    align={"left"}
                >
                    <Typography sx={{mb: 2, fontWeight: 'medium'}} variant="subtitle1" component="div">
                        More...
                    </Typography>

                    <Typography variant={"body2"}>Bundle ID: {persons.bundleIdentifier}</Typography>
                    <Typography variant={"body2"}>Uploaded: {moment.unix(persons.uploaded).format('HH:mm:ss DD/MM/YYYY')}</Typography>
                </Box>

                <Link href={persons.url} variant="body2" sx={{mb: 2, display: 'inline-block'}}>
                    {persons.url}
                </Link>

                <Box>
                    <QRCodeSVG
                        value={persons.url}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        imageSettings={{
                            src: "https://morningstudio.vn/public-images/logo_morning_100x100.png",
                            x: undefined,
                            y: undefined,
                            height: 24,
                            width: 24,
                            excavate: true,
                        }}
                    />
                </Box>

            </Box>
        </>

    )
}

export default GameDetails;