//= Mui
import {Container} from '@mui/material';

//= Components
import NotFound from "components/404";

const Page404 = () => {
    return (
        <Container>
            <NotFound />
        </Container>
    )
}

export default Page404