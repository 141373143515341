import './App.css';

import {BrowserRouter, Route, Routes} from "react-router-dom"
import React from 'react';
// Import Routes all
import { userRoutes } from "./routes/allRoutes"

function App() {
    return (
            <div className="App">
                    <Routes>
                        {userRoutes.map((route, idx) => (
                            <Route path={route.path} element={<route.component />} key={idx} />
                        ))}
                    </Routes>

            </div>
    );
}

export default App;
