//= Mui
import {Container} from '@mui/material';
//= Scripts
import {useParams} from 'react-router-dom'
//= Layout
//= Components
import GameDetails from 'components/GameDetails';

const PageGameDetails = (props) => {
    let params = useParams();

    return (
        <Container align={"center"} maxWidth="sm">
            <GameDetails slug={params.slug} />
        </Container>

    )
}

export default PageGameDetails;